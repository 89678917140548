<template>
	<DefaultLayout>
		<div class="w-full max-w-6xl p-4 mx-auto xl:px-0">
			<h1 class="w-full text-3xl font-bold">
				Search: {{ searchTerm }}
			</h1>
			<template v-if="hasResults">
				<div
					v-for="(resultSet, index) in newResults"
					:key="index"
					class="w-full pt-2"
					:resultSet="resultSet"
					:style="{'--index': index}"
				>
					<template v-if="resultHasData(resultSet)">
						<h2 class="w-full pt-4 mb-3 text-2xl font-bold text-left">
							{{ resultSet.name }}
						</h2>
						<div class="pb-2">
							<SearchResultList
								:items="resultSet.data"
								:is-mobile="isMobile"
								:list-type="resultSet.type"
								:layout-type="GRID"
								margin-classes="mx-auto"
							/>
						</div>
					</template>
				</div>
			</template>
			<SearchEmptyState
				v-else
				class="pt-8"
			>
				<template #message>
					{{ noResultsMessage }}
				</template>
				<template #buttons>
					<RouterLink
						tag="button"
						:to="'/'"
						class="py-2.5 md:py-3 w-full rounded border shadow-md border-gray-300 bg-white uppercase text-lg font-bold"
					>
						Go Home
					</RouterLink>
				</template>
			</SearchEmptyState>
		</div>
	</DefaultLayout>
</template>

<script async>
import axios from 'axios'
import { mapGetters } from 'vuex'

import SearchEmptyState from '@/components/search/SearchEmptyState.vue'
import SearchResultList from '@/components/search/SearchResultList.vue'
import { API_SERVER } from '@/constants/index.js'
import { GRID } from '@/constants/search/layoutTypes.js'
import { LOCATIONS_LIST } from '@/constants/search/listTypes.js'
import DefaultLayout from '@/layouts/default/Index.vue'
import { logError } from '@/utils/error-handling.js'

export default {
	components: {
		SearchResultList,
		DefaultLayout,
		SearchEmptyState
	},
	beforeRouteUpdate (to, from, next) {
		try {
			this.search(to.params.searchTerm)
		} catch (e) {
			logError(e, from)
		}
		next()
	},
	data: function () {
		return {
			searchResults: [],
			resultsCounts: {},
			GRID,
			noResultsMessage: 'Please double-check the spelling, try a more generic search term or explore our map'
		}
	},
	computed: {
		hasResults () {
			return !!this.searchResults.length
		},
		...mapGetters([ 'isMobile' ]),
		newResults() {
			const tempResults = []
			for (const index in this.searchResults) {
				const result = this.searchResults[index]
				if (result.type !== LOCATIONS_LIST) {
					tempResults.push(result)
				}
			}
			return tempResults
		},
		searchTerm() {
			return this.$route?.params?.searchTerm || ''
		}
	},
	mounted: async function () {
		await this.search(this.$route.params.searchTerm)
	},
	methods: {
		search: async function (searchTerm) {
			try {
				const searchResponse = await axios.get(
					`${API_SERVER}/search.php?ajaxData=true&search=${searchTerm}&limit=50`
				)
				this.resultsCounts = searchResponse.data?.counts
				this.searchResults = searchResponse.data?.data || []
			} catch (e) {
				logError(e)
			}
		},
		resultHasData (resultSet) {
			return !!resultSet.data && !!Object.keys(resultSet.data).length
		}
	}
}
</script>
