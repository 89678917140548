<template>
	<div class="w-full m-auto text-center pt-8">
		<div class="m-auto min-w-small max-w-sm p-2">
			<div class="font-bold mb-4 text-lg">
				<slot name="title">
					{{ title }}
				</slot>
			</div>
			<div class="text-gray-500 mb-8">
				<slot name="message">
					{{ message }}
				</slot>
			</div>
			<div
				v-if="$slots.default"
				class="flex flex-wrap w-full justify-content-center"
			>
				<slot name="buttons">
					<router-link
						tag="button"
						:to="{ path: '/'}"
						class="py-2.5 md:py-3 w-full rounded border shadow-md border-gray-300 bg-white uppercase text-lg font-bold"
					>
						Go Home
					</router-link>
				</slot>
			</div>
		</div>
	</div>
</template>

<script defer>
export default {
	data() {
		return {
			title: 'No Results Found',
			message: 'Please double-check the spelling, try a more generic search term'
		}
	}
}
</script>
